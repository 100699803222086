import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'

const app = createApp(App)

axios.defaults.baseURL = 'https://api.wiwiianime.online/v1/'
axios.defaults.headers.common['Authorization'] =
  'vMvlG0YURfbd0JrThB3HPZCiz7fY8Dww'
axios.defaults.headers.common['App-version'] = 1.1

app.use(store)
const userInfo = store.getters.UserInfo
if (userInfo !== null) {
  axios.defaults.headers.common['token'] = store.getters.UserInfo.token
}
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsExample', DocsExample)

app.mount('#app')
